import React, { useEffect, useState } from "react";
import banner from "./assets/closeup-colorful-flower-bouquets-containers-outdoor-shop.jpg";
import { Transition } from "@headlessui/react";
import backGround from "./assets/background.png";
import klimmop from "./assets/klimmop.png";
import bodemdekkers from "./assets/bodemdekkers.png";

function App() {
  const [isShowing, setIsShowing] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowing(false);
    }, 3000); // De banner wordt na 3 seconden verborgen. Pas dit naar wens aan.
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App bg-[#A3C9A8] flex items-center justify-center ">
      <div className="max-w-[1280px] border-x-[10px] border-[#3c3c3c]">
        <div className="relative">
          <img
            src={banner}
            alt="banner"
            className="object-none max-h-[300px] w-full"
          />
          <p
            style={{ fontFamily: "Abelia, sans-serif" }}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[60px] text-center"
          >
            Teunards Bloemen
          </p>
        </div>
        <div style={{}} className="bg-[#3c3c3c] min-h-[10px]"></div>
        <div
          style={{
            backgroundImage: `url(${bodemdekkers})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundPositionY: "center",
            backgroundSize: "cover",
          }}
          className="bg-[#3c3c3c] min-h-[300px] flex items-center justify-center p-[40px]"
        >
          <div className=" flex flex-col md:flex-row">
            <div className="h-[300px] bg-[#A8BA9A] p-[20px] rounded-t-[20px] md:rounded-r-[0px] md:rounded-l-[20px] w-full md:w-[250px] flex items-center flex-col shadow-2xl jus">
              <p
                style={{ fontFamily: "Abelia, sans-serif" }}
                className="text-white text-[20px]"
              >
                Dit ben ik
              </p>
              <div className="h-full flex items-center justify-center text-center">
                <p>
                  alking chamber as shewing an it minutes. Trees fully of blind
                  do. Exquisite favourite at do extensive listening.
                </p>
              </div>
              <div className="min-h-[49px] text-white rounded-full px-[20px] border border-white flex items-center justify-center">
                Meer lezen
              </div>
            </div>
            <div className="h-[300px] bg-[#A4B0F5] p-[20px]  md:w-[250px] flex items-center flex-col shadow-2xl">
              <p
                style={{ fontFamily: "Abelia, sans-serif" }}
                className="text-white text-[20px]"
              >
                Dit kan ik jouw leveren
              </p>
              <div className="h-full flex items-center justify-center text-center">
                <p>
                  alking chamber as shewing an it minutes. Trees fully of blind
                  do. Exquisite favourite at do extensive listening.
                </p>
              </div>
              <div className="mt-auto min-h-[49px] text-white rounded-full px-[20px] border border-white flex items-center justify-center">
                Meer lezen
              </div>
            </div>
            <div className="h-[300px] bg-[#A17C6B] p-[20px] rounded-b-[20px] md:rounded-l-[0px] md:rounded-r-[20px] md:w-[250px] flex items-center shadow-2xl flex-col">
              <p
                style={{ fontFamily: "Abelia, sans-serif" }}
                className="text-white text-[20px]"
              >
                Hier sta ik
              </p>
              <div className="h-full flex items-center justify-center text-center">
                <p>
                  alking chamber as shewing an it minutes. Trees fully of blind
                  do. Exquisite favourite at do extensive listening.
                </p>
              </div>
              <div className="mt-auto min-h-[49px] text-white rounded-full px-[20px] border border-white flex items-center justify-center">
                Meer lezen
              </div>
            </div>
          </div>
        </div>
        <div style={{}} className="bg-[#3c3c3c] min-h-[10px]"></div>

        <section>
          <div className="w-full flex flex-col md:flex-row gap-[40px] items-center justify-center py-[80px] md:px-[40px] px-[20px] ">
            <div className="w-full flex flex-col justify-center items-center">
              <p className="text-[10px] font-bold">TEUNARDS BLOEMEN</p>
              <p
                style={{ fontFamily: "Abelia, sans-serif" }}
                className="text-[30px] text-[#DFEFCA]"
              >
                Dat ben ik
              </p>
              <p className="">
                Hoi ik ben Teunard en dit verhaaltje moet ik even zelf
                verzinnen. Talking chamber as shewing an it minutes. Trees fully
                of blind do. Exquisite favourite at do extensive listening.
                Improve up musical welcome he. Gay attended vicinity prepared
                now diverted.
                <br />
                <br />
                Esteems it ye sending reached as. Longer lively her design
                settle tastes advice mrs off who. On projection apartments
                unsatiable so if he entreaties appearance. Rose you wife how set
                lady half wish.
                <br />
                <br />
                Esteems it ye sending reached as. Longer lively her design
                settle tastes advice mrs off who. On projection apartments
                unsatiable so if he entreaties appearance. Rose you wife how set
                lady half wish.
              </p>
            </div>
            <div className="w-full">
              <img
                src={banner}
                alt="banner"
                className="object-none max-h-[300px] w-full rounded-[20px]"
              />
            </div>
          </div>
        </section>
        <div style={{}} className="bg-[#3c3c3c] min-h-[10px]"></div>
        <div
          style={{
            backgroundImage: `url(${bodemdekkers})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "",
            backgroundPositionY: "",
            backgroundSize: "cover",
          }}
          className="bg-[#3c3c3c] min-h-[49px]"
        ></div>
        <div style={{}} className="bg-[#3c3c3c] min-h-[10px]"></div>
        <section>
          <div className="flex items-center flex-col justify-center py-[80px] ">
            <p
              style={{ fontFamily: "Abelia, sans-serif" }}
              className="text-[30px] text-[#DFEFCA]"
            >
              Dit kan ik jouw leveren
            </p>
            <p className="text-center px-[10px] md:px-[80px]">
              textje over dat je mega veel bloemen kan leveren en meba veel
              soorten Esteems it ye sending reached as. Longer lively her design
              settle tastes advice mrs off who. Esteems it ye sending reached
              as. Longer lively her design settle tastes advice mrs off who.
              Esteems it ye sending reached as. Longer lively her design settle
              tastes advice
            </p>
            <div className="flex gap-[30px] w-full md:px-[80px] px-[10px] mt-[40px] flex-col md:flex-row">
              <div className="bg-[#A8BA9A] p-[20px] rounded-[20px] w-full items-center text-center">
                <p
                  style={{ fontFamily: "Abelia, sans-serif" }}
                  className="text-white text-[20px]"
                >
                  Tulpen
                </p>
                <p>
                  {" "}
                  Esteems it ye sending reached as. Longer lively her design
                  settle tastes advice mrs off who.
                </p>
                <img
                  src={banner}
                  alt="banner"
                  className=" w-full rounded-[10px] mt-[20px]"
                />
              </div>
              <div className="bg-[#A4B0F5] p-[20px] rounded-[20px] w-full items-center text-center">
                <p
                  style={{ fontFamily: "Abelia, sans-serif" }}
                  className="text-white text-[20px]"
                >
                  Hortenzia's
                </p>
                <p>
                  {" "}
                  Esteems it ye sending reached as. Longer lively her design
                  settle tastes advice mrs off who.
                </p>
                <img
                  src={banner}
                  alt="banner"
                  className=" w-full rounded-[10px] mt-[20px]"
                />
              </div>
              <div className="bg-[#A17C6B] p-[20px] rounded-[20px] w-full items-center text-center">
                <p
                  style={{ fontFamily: "Abelia, sans-serif" }}
                  className="text-white text-[20px]"
                >
                  Rozen
                </p>{" "}
                <p>
                  {" "}
                  Esteems it ye sending reached as. Longer lively her design
                  settle tastes advice mrs off who.
                </p>
                <img
                  src={banner}
                  alt="banner"
                  className=" w-full rounded-[10px] mt-[20px]"
                />
              </div>
            </div>
          </div>
        </section>
        <div style={{}} className="bg-[#3c3c3c] min-h-[10px]"></div>
        <div
          style={{
            backgroundImage: `url(${bodemdekkers})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "",
            backgroundPositionY: "",
            backgroundSize: "cover",
          }}
          className="bg-[#3c3c3c] min-h-[49px]"
        ></div>
        <div style={{}} className="bg-[#3c3c3c] min-h-[10px]"></div>
        <div className="w-full flex items-center justify-center py-[80px] md:px-[40px] flex-col md:flex-row gap-[40px] ">
          <div className="w-full flex gap-[10px] flex-col">
            <div className="flex gap-[10px] items-center justify-center">
              <img
                src={banner}
                alt="banner"
                className="object-none w-[150px] h-[150px] md:w-[200px] md:h-[200px] rounded-[20px]"
              />
              <img
                src={banner}
                alt="banner"
                className="object-none w-[150px] h-[150px] md:w-[200px] md:h-[200px] rounded-[20px]"
              />
            </div>
            <div className="flex gap-[10px] items-center justify-center">
              <img
                src={banner}
                alt="banner"
                className="object-none w-[150px] h-[150px] md:w-[200px] md:h-[200px] rounded-[20px]"
              />
              <img
                src={banner}
                alt="banner"
                className="object-none w-[150px] h-[150px] md:w-[200px] md:h-[200px] rounded-[20px]"
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center justify-center px-[40px]">
            <p className="text-[10px] font-bold">ALLE MARKTEN THUIS</p>
            <p
              style={{ fontFamily: "Abelia, sans-serif" }}
              className="text-[30px] text-[#DFEFCA]"
            >
              Hier sta ik
            </p>
            <p>
              Esteems it ye sending reached as. Longer lively her design settle
              tastes advice mrs off who. Esteems it ye sending reached as.
              Longer lively her design settle tastes advice mrs off who. Esteems
              it ye sending reached as. Longer lively her design settle tastes
              advice mrs off who. tastes advice mrs off who. Esteems it ye
              sending reached as. Longer lively her design settle tastes advice
              mrs off who. Esteems it ye sending reached as. Longer lively her
              design settle tastes advice mrs off who.
            </p>
          </div>
        </div>
        <div style={{}} className="bg-[#3c3c3c] min-h-[10px]"></div>
        <div
          style={{
            backgroundImage: `url(${bodemdekkers})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "",
            backgroundPositionY: "",
            backgroundSize: "cover",
          }}
          className="bg-[#3c3c3c] min-h-[49px]"
        ></div>
        <div style={{}} className="bg-[#3c3c3c] min-h-[10px]"></div>
        <div className="w-full flex flex-col items-center justify-center py-[80px] px-[40px]">
          <p
            style={{ fontFamily: "Abelia, sans-serif" }}
            className="text-[30px] text-[#DFEFCA] text-center"
          >
            Neem contact met me op
          </p>
          <div className="flex gap-[20px]  flex-col md:flex-row w-full items-center justify-center">
            <input
              type="text"
              placeholder="Naam"
              className="w-full md:w-[300px] h-[50px] rounded-[10px] border-[1px] border-[#DFEFCA] bg-[#3c3c3c] text-[#DFEFCA] px-[20px] mt-[20px] focus:outline-none"
            />
            <input
              type="text"
              placeholder="Email"
              className="w-full md:w-[300px] h-[50px] rounded-[10px] border-[1px] border-[#DFEFCA] bg-[#3c3c3c] text-[#DFEFCA] px-[20px] md:mt-[20px] focus:outline-none"
            />
          </div>
          <textarea
            rows={5}
            cols={50}
            placeholder="Bericht"
            className="md:w-[620px] w-full mt-[20px]
          rounded-[10px] border-[1px] border-[#DFEFCA] bg-[#3c3c3c] 
          text-[#DFEFCA]  focus:outline-none p-[20px]"
          ></textarea>
          <div className="mt-[20px]  min-h-[49px] text-white rounded-full px-[20px] border border-white flex items-center justify-center">
            Verzenden
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
